import useSWR from "swr";
import { jwtAxios } from "../services/axios";
import { useMemo } from "react";
import { createFolderFromData } from "../services/utils";

function useProjectAndFolder() {
  const { data, error, isLoading } = useSWR("/projects", async () => {
    const resp = await jwtAxios.get(`/projects`);
    return resp.data["projects"];
  });

  const groupedData = useMemo(() => {
    if (!data) {
      return null;
    }
    return createFolderFromData(data);
  }, [data]);

  return { isLoading, projects: data, folders: groupedData, error };
}

export { useProjectAndFolder };
