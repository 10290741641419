import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { mutate } from "swr";
import { jwtAxios } from "../services/axios";

function DeleteProjectDialog(props: { id: string }) {
  const { id } = props;
  const cancelRef = React.useRef<any>();
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleDelete = useCallback(async () => {
    try {
      setIsLoading(true);
      const resp = await jwtAxios.post(`/projects/${id}/delete`);
      console.log("delete", resp.data.success);
      mutate("/projects");
    } catch (e) {
    } finally {
      setIsLoading(false);
      onClose();
    }
  }, [id, onClose]);

  return (
    <>
      <Text onClick={onOpen}>Delete Image</Text>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete this Image?
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button isLoading={isLoading} colorScheme="red" onClick={handleDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

export { DeleteProjectDialog };
