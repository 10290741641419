import withLayout from "../hoc/withLayout";
import {
  Box,
  Card,
  CardBody,
  CardFooter,
  Text,
  CircularProgress,
  Grid,
  GridItem,
  Image,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { DeleteProjectDialog } from "./DeleteProjectDialog";
import { CreateProjectFolderDialog } from "./CreateProjectFolderDialog";
import { FiMenu } from "react-icons/fi";
import { MoveProjectFolderDialog } from "./MoveProjectDialog";
import { useProjectAndFolder } from "../hooks/useProjectAndFolder";

function ProjectMenu(props: any) {
  const { projectId } = props;
  return (
    <Menu>
      <MenuButton as={IconButton} aria-label="Options" icon={<FiMenu />} variant="outline" />
      <MenuList>
        <MenuItem>
          <a href={`/editor?id=${projectId}`}>
            <Text>Open Image</Text>
          </a>
        </MenuItem>
        <MenuItem>
          <DeleteProjectDialog id={projectId} />
        </MenuItem>
        <MenuItem>
          <MoveProjectFolderDialog projectId={projectId} />
        </MenuItem>
        <MenuItem>
          <CreateProjectFolderDialog projectId={projectId} />
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

function Projects() {
  const { isLoading, projects, folders } = useProjectAndFolder();

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const showResult = useMemo(() => {
    const groupResults = id && folders ? folders[id] : projects;
    return groupResults;
  }, [projects, folders, id]);

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      {isLoading ? (
        <CircularProgress isIndeterminate/>
      ) : !showResult ? (
        <Box><Text>No Projects</Text></Box>
      ) : (
        <Grid templateColumns="repeat(3, 1fr)" gap={6}>
          {showResult.map((row: any) => (
            <GridItem>
              <Card>
                <CardBody>
                  <a href={`/editor?id=${row.project_id}`}>
                    <Image src={row.image_url}></Image>
                  </a>
                </CardBody>
                <CardFooter>
                  <ProjectMenu projectId={row.project_id} />
                </CardFooter>
              </Card>
            </GridItem>
          ))}
        </Grid>
      )}
    </Box>
  );
}

export default withLayout(Projects);
