import axios from "axios";
import { API_HOST } from "../config";
import { auth } from "./firebase";

const jwtAxios = axios.create({
  baseURL: API_HOST, //YOUR_API_URL HERE
  headers: {
    "Content-Type": "application/json",
  },
});
const FIREBASE_AUTH_KEY_STORAGE = 'firebaseauth-jwt'

jwtAxios.interceptors.request.use(async (config) => {
  const { currentUser } = auth;
  config.headers.authorization = localStorage.getItem(FIREBASE_AUTH_KEY_STORAGE)
  if(currentUser){
    localStorage.setItem(FIREBASE_AUTH_KEY_STORAGE, await currentUser.getIdToken())
  }
  return config;

});
export { jwtAxios };
