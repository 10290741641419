import { Box, useColorModeValue } from "@chakra-ui/react";
import { SidebarContent } from "../components/SideBar";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../services/firebase";
import { Login } from "../components/Login";

const withLayout = (Component: any) => (props: any) => {
  const [user, loading, error] = useAuthState(auth);
  const bg = useColorModeValue("gray.100", "gray.900");

  if (!loading && !user) {
    return <Login />;
  }

  return (
    <Box display="flex" minH="100vh" bg={bg}>
      <SidebarContent
        onClose={function (): void {
          throw new Error("Function not implemented.");
        }}
      />
      <Box flex="1 0" px={4} py={2}>
        <Component {...props} />
        </Box>
    </Box>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default withLayout;
