// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDZBovyazxH9Yjt6G7Xd50-7qDMuGUCC5I",
  authDomain: "ai-eye-homerun.firebaseapp.com",
  projectId: "ai-eye-homerun",
  storageBucket: "ai-eye-homerun.appspot.com",
  messagingSenderId: "1032450742717",
  appId: "1:1032450742717:web:d96dedf419157797105ad4",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
export { firebaseApp, auth };
