import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Text,
  useDisclosure,
  Input,
} from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { mutate } from "swr";
import { jwtAxios } from "../services/axios";
import { toast } from "react-toastify";
import { v4 } from "uuid";

function CreateProjectFolderDialog(props: { projectId: string }) {
  const { projectId } = props;
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<any>();
  const handleChangeName = useCallback(async () => {
    setIsLoading(true);
    const resp = await jwtAxios.post(`/projects/${projectId}/name`, { name: name + "/" + v4() });
    toast(`Saved`, { type: "success" });
    mutate("/projects");
    setIsLoading(false);
    onClose();
  }, [name, onClose, projectId]);

  return (
    <>
      <Text onClick={onOpen}>Create New Project Folder</Text>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Create New Project Folder
            </AlertDialogHeader>

            <AlertDialogBody>
              <Input placeholder="New Folder Name" onChange={(e) => setName(e.target.value)} value={name} />
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button isDisabled={!name} isLoading={isLoading} colorScheme="blue" onClick={handleChangeName} ml={3}>
                Create Folder
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

export { CreateProjectFolderDialog };
