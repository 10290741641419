import "./App.css";
import { ChakraProvider } from "@chakra-ui/react";
import Editor from "./components/Editor";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Index from "./components/Index";
import { SWRConfig } from "swr";
import Projects from "./components/Projects";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Index />,
  },
  {
    path: "/editor",
    element: <Editor />,
  },
  {
    path: "/projects",
    element: <Projects />,
  },
]);

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <SWRConfig
        value={{
          shouldRetryOnError: true,
          errorRetryCount: 10,
        }}
      >
        <ChakraProvider>
          <RouterProvider router={router} />
        </ChakraProvider>
      </SWRConfig>
    </div>
  );
}

export default App;
