import { NOFOLDER_NAME } from "../config";


function readFileContent(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (e: any) {
      const contents = e.target.result;
      resolve(contents.toString());
    };
    reader.readAsText(file);
  });
}

function processAnnotation(objects: any) {
  const results = objects
    .map((obj: any) => {
      // comment body
      // b.purpose: "commenting"
      //   obj.body.map((b) => b.value);
      const target = obj.target.selector;
      // selector.type === "FragmentSelector"
      // value "xywh=pixel:1096,261,529,691"
      // selector.type === "SvgSelector"
      // value "<svg><polygon points="945,385 1119,277 1287,421 1042,644 844,565 996,502"></polygon></svg>"
      const targetValue = target.value;
      if (target.type === "FragmentSelector") {
        const coords = targetValue
          .split("=")[1]
          .split(":")[1]
          .split(",")
          .map((a: any) => parseInt(a));

        return {
          type: "bbox",
          // xywh: { x: coords[0], y: coords[1], w: coords[2], h: coords[3] },
          tlbr: [
            [coords[0], coords[1]],
            [coords[0] + coords[2], coords[1] + coords[3]],
          ],
        };
      } else if (target.type === "SvgSelector") {
        // "<svg><polygon points="945,385 1119,277 1287,421 1042,644 844,565 996,502"></polygon></svg>"
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(targetValue, "image/svg+xml");
        // Select the polygon element
        const polygon = svgDoc.querySelector("polygon")!;
        // Extract the points attribute
        const pointsString = polygon.getAttribute("points")!;
        // Split the points string into an array of individual points
        const pointsArray = pointsString.split(" ");
        // Map the array of points to an array of {x, y} objects
        const points = pointsArray.map((point) => {
          const [x, y] = point.split(",").map(Number);
          return [x, y];
        });
        return { type: "polygon", points };
      } else {
        return null;
      }
    })
    .filter((a: any) => a);
  return results;
}

async function toDataURL(url: string) {
  const blob = await fetch(url).then((res) => res.blob());
  return URL.createObjectURL(blob);
}
async function download(url: string, filename?: string) {
  const a = document.createElement("a");
  a.href = await toDataURL(url);
  a.download = filename ? filename : "result.png";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function createFolderFromData(data: any) {
  const folderData: any = {
    [NOFOLDER_NAME]: []
  };
  for (let i = 0; i < data.length; i++) {
    let key;
    if (data[i].name) {
      if (data[i].name.includes("/")) {
        const k = data[i].name.split("/");
        const subFolder = k.filter((_: any, j: number) => j <= k.length - 2).join("/");
        key = subFolder;
      } else {
        key = NOFOLDER_NAME;
      }
    } else {
      key = NOFOLDER_NAME;
    }
    if (!folderData[key]) {
      folderData[key] = [];
    }
    folderData[key].push(data[i]);
  }
  return folderData;
}

export { readFileContent, processAnnotation, download, delay, createFolderFromData };
