import { jwtAxios } from "./axios";

class API {
  async uploadSrcImage(file: any, type: string) {
    const result = await jwtAxios.post("get-upload-url", { ext: type });
    const { upload_url: uploadUrl, filepath } = result.data;

    const response = await fetch(uploadUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "application/octet-stream",
      },
      body: file,
    });

    console.log(response);
    return [filepath, response];
  }

  async createNewPrediction(srcPath: string, bbox: any, withPercent: boolean) {
    const bboxPayload = withPercent ? bbox : []
    const resp = await jwtAxios.post("inference", {
      image_path: srcPath,
      bbox: bboxPayload,
    });
    return resp;
  }
}

const api = new API();

export { api };
