import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    Text,
    useDisclosure,
    Input,
  } from "@chakra-ui/react";
  import React, { useCallback, useState } from "react";
  import { jwtAxios } from "../services/axios";
  import { toast } from "react-toastify";
  import { useProjectAndFolder } from "../hooks/useProjectAndFolder";
  import { mutate } from "swr";
  
  function RenameFolderDialog(props: { folderName: string, }) {
    const { folderName } = props;
    const {folders} = useProjectAndFolder()
    const [name, setName] = useState(folderName);
    const [isLoading, setIsLoading] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = React.useRef<any>();
  
    const handleMove = useCallback(async () => {
      setIsLoading(true);
      const projectIds = folders[folderName].map((row: any) => row.project_id)
      console.log('projectIds', projectIds, name, folderName)
      const resp = await jwtAxios.post(`/projects_batch/update/name`, { name: name, project_ids: projectIds });
      toast(`Saved`, { type: "success" });
      mutate("/projects");
      setIsLoading(false);
      onClose();
    }, [folders, folderName, name, onClose]);
  
    return (
      <>
        <Text onClick={onOpen}>Rename Folder</Text>
        <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Rename Folder
              </AlertDialogHeader>
  
              <AlertDialogBody>
                <Input placeholder="New Folder Name" onChange={(e) => setName(e.target.value)} value={name} />

              </AlertDialogBody>
  
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button isDisabled={!name} isLoading={isLoading} colorScheme="blue" onClick={handleMove} ml={3}>
                  Rename
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    );
  }
  
  export { RenameFolderDialog };
  