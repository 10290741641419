// import { Box, Button, IconButton, Typography } from "@mui/material";
// import { LoadingButton } from "@mui/lab";
import { Box, Button, Divider, IconButton, Text } from "@chakra-ui/react";
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { FaDrawPolygon } from "react-icons/fa";
import { FaRegEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { Annotorious } from "@recogito/annotorious";
import { delay, download, processAnnotation } from "../services/utils";
import { api } from "../services/api";
import "@recogito/annotorious/dist/annotorious.min.css";
import withLayout from "../hoc/withLayout";
import { useSearchParams } from "react-router-dom";
import useSWR, { mutate } from "swr";
import { jwtAxios } from "../services/axios";

function Editor() {
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const annoRef = useRef<Annotorious>();
  const containerRef = useRef<any>();
  const [processing, setProcessing] = useState(false);
  const [scale, setScale] = useState(1);
  const [mode, setMode] = useState<string>("rect");
  const imgRef = useRef<any>();
  const [img, setImg] = useState<string>();

  useEffect(() => {
    mutate(`project/${id}`);
  }, [id]);

  const { data, error, isLoading } = useSWR(`project/${id}`, async () => {
    const resp = await jwtAxios.get(`/projects/${id}`);
    return resp.data["project"];
  });

  useEffect(() => {
    (async () => {
      if (!data) {
        return;
      }
      const resp = await jwtAxios.post(`/get-signed-url`, {
        blob_path: data.image_path,
      });
      const imgUrl = resp.data.url;
      setImg(imgUrl);
      const annotations = data.annotations || [];
      setTimeout(async () => {
        await annoRef.current.clearAnnotations();
        await annoRef.current.setAnnotations(annotations);
      }, 1000);
    })();
  }, [data]);

  useEffect(() => {
    if (img) {
      annoRef.current = new Annotorious({ image: imgRef.current, widgets: [], allowEmpty: true });
    }
  }, [img]);

  // polygon / rect
  const handleChangeMode = useCallback((newMode: string) => {
    annoRef.current.setDrawingTool(newMode);
    setMode(newMode);
  }, []);

  const handleSave = useCallback(async () => {
    const objects = annoRef.current.getAnnotations();
    await jwtAxios.post(`/projects/${id}`, { annotations: objects });
    toast(`Saved`, { type: "success" });
  }, [id]);

  const handleSaveAndExport = useCallback(async (withPercent: boolean) => {
    try {
      if (!img) {
        return;
      }
      setProcessing(true);
      let blobpath = "upload/" + img.split("?")[0].split("/upload/")[1];
      const objects = annoRef.current.getAnnotations();
      await jwtAxios.post(`/projects/${id}`, { annotations: objects });
      await delay(5000);
      const bbox = processAnnotation(objects);
      const resp = await api.createNewPrediction(blobpath, bbox, withPercent);
      const result = resp.data.result;
      const filename = format(new Date(), "dd/MM/yyyy-HH:mm:ss") + ".png";
      download(result, filename);
      console.log(result);
      toast(`Success`, { type: "success" });
    } catch (e: any) {
      toast(`Error: ${e.toString()}`, { type: "error" });
    } finally {
      await delay(1000);
      setProcessing(false);
      window.location.reload();
    }
  }, [id, img]);

  const handleSetScale = useCallback(() => {
    if (!img) {
      return;
    }
    const imgElement = new Image();
    imgElement.onload = function () {
      const imgHeight = imgElement.height;
      const imgWidth = imgElement.width;
      const containerSize = containerRef.current!.getBoundingClientRect();
      const scaleX = containerSize.width / imgWidth;
      const scaleY = containerSize.height / imgHeight;
      const scale = Math.min(scaleX, scaleY);
      if (scale > 1.0) {
        return;
      }
      setScale(scale);
    };
    imgElement.src = img;
  }, [img]);

  useLayoutEffect(() => {
    handleSetScale();
  }, [handleSetScale]);

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 2, mt: 1, mx: 4 }}>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Text sx={{ mr: 2, }} fontWeight={600}>Selection Tool</Text>
          <Box style={{ display: "flex", borderRight: '1px solid #444' }}>
            <IconButton
              color={mode === "polygon" ? "blue" : undefined}
              onClick={() => handleChangeMode("polygon")}
              icon={<FaDrawPolygon />}
              aria-label={"draw polygon"}
            ></IconButton>
            <IconButton
              color={mode === "rect" ? "blue" : undefined}
              onClick={() => handleChangeMode("rect")}
              icon={<FaRegEdit />}
              aria-label="draw rect"
            ></IconButton>
          </Box>
        </Box>
        <Box style={{display:'flex'}}>
        <Box style={{display:'flex', justifyContent:'right'}}>
          <Button fontWeight={400} textDecoration="underline" onClick={handleSave}>Save</Button>
          </Box>
        <Box sx={{ borderLeft: '1px solid #444', display:'flex', alignItems:'center', pl: 4}}>
          <Text fontWeight={600}>AI Processing</Text>
          <Button textDecoration="underline" fontWeight={400} isLoading={processing} onClick={() => handleSaveAndExport(false)}>
            Heatmap only
          </Button>
          <Button textDecoration="underline" fontWeight={400} isLoading={processing} onClick={() => handleSaveAndExport(true)}>
            Heatmap with %
          </Button>
        </Box>
        </Box>
      </Box>
      <Box
        ref={containerRef}
        style={{
          width: "100%",
          height: "calc(100% - 100px)",
          // overflow: "hidden",
          display: "flex",
        }}
      >
        {img && (
          <Box
            style={{
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
              display: "flex",
            }}
          >
            <Box style={{ scale: `${scale}`, transformOrigin: "center" }}>
              <img alt="" ref={imgRef} src={img} />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default withLayout(Editor);
