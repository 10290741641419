import {
  Box,
  Flex,
  Image,
  useColorModeValue,
  Text,
  CloseButton,
  BoxProps,
  Divider,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  CircularProgress,
} from "@chakra-ui/react";
import { CreateProjectDialog } from "./CreateProjectDialog";
import { useProjectAndFolder } from "../hooks/useProjectAndFolder";
import Logo from "../images/logo.png";
import { useMemo } from "react";
import { RenameFolderDialog } from "./RenameFolderDialog";
import { FiMenu } from "react-icons/fi";
import { NOFOLDER_NAME } from "../config";

interface SidebarProps extends BoxProps {
  onClose: () => void;
}
function FolderMenu(props: { folderName: string }) {
  const { folderName } = props;
  return (
    <Menu>
      <MenuButton as={IconButton} aria-label="Options" icon={<FiMenu />} variant="outline" />
      <MenuList zIndex={999}>
        <MenuItem zIndex={999}>
          <RenameFolderDialog folderName={folderName} />
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const { isLoading, folders } = useProjectAndFolder();

  const sortableFolder = useMemo(() => {
    const folderKeys = folders ? Object.keys(folders) : [];
    folderKeys.sort();
    return folderKeys;
  }, [folders]);

  return (
    <Box
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={60}
      minH="100vh"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" my="4" justifyContent="space-between">
        <a href="/">
          <Image alt="" src={Logo} />
        </a>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      <CreateProjectDialog />
      <Box sx={{ mb: 4 }}></Box>
      <Divider />
      <Box textAlign="left" mx="8">
        <Text sx={{ my: 2 }} fontSize={28} fontWeight={600}>
          Projects
        </Text>
        <div className="flex">
          {isLoading && (
            <Box display="flex" justifyContent={"center"}>
              <CircularProgress isIndeterminate />
            </Box>
          )}
          {sortableFolder.map((folderName, i) => (
            <Box h={50} key={folderName + i} display="flex" alignItems="center" justifyContent="space-between">
              <Text fontSize="large" variant="h3">
                <a href={`/projects?id=${folderName}`}>{folderName}</a>
              </Text>
              {folderName !== NOFOLDER_NAME && <FolderMenu folderName={folderName} />}
            </Box>
          ))}
        </div>
      </Box>
    </Box>
  );
};

export { SidebarContent };
