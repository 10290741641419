import withLayout from "../hoc/withLayout";
import { Box, CircularProgress, Text } from "@chakra-ui/react";
import { useProjectAndFolder } from "../hooks/useProjectAndFolder";

function Index() {
  const { isLoading } = useProjectAndFolder();
  return (
    <Box my={8}>
      {isLoading ? (
        <Box>
          <Text mb={4} fontSize={"x-large"}>Loading ....</Text>
          <CircularProgress isIndeterminate />
        </Box>
      ) : (
        <Text fontSize="x-large">Please select project on the left to start</Text>
      )}
    </Box>
  );
}

export default withLayout(Index);
