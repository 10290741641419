import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Text,
  Card,
  useDisclosure,
  Select,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FiEdit } from "react-icons/fi";
import Dropzone from "react-dropzone";
import { api } from "../services/api";
import { mutate } from "swr";
import { jwtAxios } from "../services/axios";
import { toast } from "react-toastify";
import { NOFOLDER_NAME } from "../config";
import { useProjectAndFolder } from "../hooks/useProjectAndFolder";
import { v4 } from "uuid";

function CreateProjectDialog() {
  const { folders } = useProjectAndFolder();
  const [folderName, setFolderName] = useState<string>(NOFOLDER_NAME);
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [file, setFile] = useState<any>();
  const cancelRef = React.useRef<any>();

  const updateFiles = useCallback((incommingFiles: any) => {
    setFile(incommingFiles[0]);
    console.log(incommingFiles[0]);
  }, []);

  const handleCreateProject = useCallback(async () => {
    if (!file) {
      return;
    }
    setIsLoading(true);
    const type = file.type.replace("image/", "");
    const [fp] = await api.uploadSrcImage(file, type);
    const resp = await jwtAxios.post(`/projects/create`, {
      image_path: fp,
      name: folderName + '/' + v4(),
    });
    console.log(resp.data);
    mutate("/projects");
    setFile(null);
    setIsLoading(false);
    toast.success(`Saved; The new image saved in "${folderName}" project.`);
    onClose();
  }, [file, folderName, onClose]);

  const projectNames = useMemo(() => {
    return folders ? Object.keys(folders) : [NOFOLDER_NAME];
  }, [folders]);

  useEffect(() => {
    setFile(null);
  }, [isOpen]);

  return (
    <>
      <Button leftIcon={<FiEdit />} onClick={onOpen}>
        New Image
      </Button>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Upload New Image
            </AlertDialogHeader>

            <AlertDialogBody>
              <Dropzone
                onDrop={updateFiles}
                accept={{
                  "image/jpeg": [],
                  "image/png": [],
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <Card
                      {...getRootProps()}
                      style={{
                        width: "100%",
                        height: 200,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <input {...getInputProps()} />
                      {file ? <Text>File Selected</Text> : <Text>Select File To Upload</Text>}
                    </Card>
                  </section>
                )}
              </Dropzone>
              <Text>Select folder for this image</Text>
              <Select placeholder="Select folder" value={folderName} onChange={(e) => setFolderName(e.target.value)}>
                {projectNames.map((row) => (
                  <option value={row}>{row}</option>
                ))}
              </Select>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button isDisabled={!file} isLoading={isLoading} colorScheme="blue" onClick={handleCreateProject} ml={3}>
                Upload
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

export { CreateProjectDialog };
